.Footer {
  width: 100%;
  background: #414142;
  border-top: 5px solid #babac3;
}
.FooterCont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  max-width: 980px;
  margin: 0 auto;
  align-items: center;
  color: white;
  font-size: 18px;
  padding: 0 30px;
}
.FooterCont a,
.FooterCont a:hover,
.FooterCont a:visited {
  color: white;
  text-decoration: underline;
}
.copyR {
  font-size: 14px;
  padding: 40px 0;
  flex-grow: 1;
  text-align: right;
}
.linkBack {
  font-size: 14px;
  padding: 40px 0;
  flex-grow: 1;
}
.FooterNotFound {
  position: absolute;
  bottom: 0;
}
@media all and (max-width: 800px) {
  .copyR {
    font-size: 12px;
    width: 100%;
    text-align: right;
    padding: 5px 5px 10px 0;
  }
  .linkBack {
    font-size: 14px;
    width: 100%;
    text-align: left;
    padding: 20px 5px 10px 0;
  }
  .FooterCont {
    flex-direction: column;
  }
}
