.MenuDiv {
  position: fixed;
  background-color: #c1001d;
  bottom: 20px;
  width: 26px;
  padding: 10px 0 10px 0;
  display: flex;
  flex-direction: column;
  border-left: 3px solid #606061;
  z-index: 20000;
  left: 0;
}
.Menus {
  position: fixed;
  top: 30%;
  z-index: 20000;
  display: flex;
  flex-direction: column;
}
.MenuDivSecond {
  z-index: 20000;
}
.MenuItem {
  width: 28px;
  /* position: fixed; */
  display: flex;
  flex-direction: column;
  border-left: 3px solid #606061;
  border-radius: 0 5px 5px 0;
  left: 0;
  padding: 10px 3px;
  margin-bottom: 3px;
  z-index: 9000;
}
.MenuReturn {
  /* margin-top: 115px; */
}
.LeftMenuIcons {
  cursor: pointer;
  padding: 5px 3px 2px 6px;
}
.CatImage {
  padding: 10px 12px;
  cursor: pointer;
}
.MenuReturnText {
  display: block;
  cursor: pointer;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  text-decoration: none;
  color: white;
  font-weight: 550;
  text-align: center;
  font-size: 15px;
  padding-right: 2px;
}
.MenuRecentlyViewed {
  /* margin-top: 183px; */
}
.LeftMenuIconsLanguage {
  cursor: pointer;
  text-align: center;
  color: white;
  font-weight: bold;
}
@media all and (max-width: 1150px) {
  .MenuDiv,
  .MenuDivSecond,
  .Menus {
    display: none;
  }
}
