.RecentlyBodyStyle {
  position: fixed;
  top: 27.5%;
  left: 53px;
  background-color: rgba(230, 230, 230, 0.9);
  border-radius: 6px;
}
.RecentlyBodyStyleItems {
  display: inline-block;
  width: 800px;
  height: 450px;
  padding: 10px;
}
.RecentlyProductsList {
  display: flex;
  /* justify-content: center;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, 240px);
  grid-template-rows: repeat(2, 340px); 
  position: relative; */
}
.RecentlyProductsTitle {
  display: flex;
  justify-content: space-between;
  margin: 0;
  height: 30px;
}
.RecentlyProductsTitle > h2,
.RecentlyProductsTitle > h1 {
  margin: 0;
}
.RecentlyProductsItem {
  flex: 1 0 30%;
  margin: 0 5px;
}
.RecentlyProductsTitle .Icon {
  height: 20px;
  cursor: pointer;
  color: black;
  width: 20px;
}
