.imgLogo {
  margin: auto;
  display: block;
  max-height: 150px;
  max-width: 250px;
  width: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.supplierItem {
  padding: 0 15px;
  position: relative;
  /* border: 1px solid red; */
  height: 150px;
}
