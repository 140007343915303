.companyMenuLine {
  background-color: #f7f7f7;
  /* margin-top: 10px; */
}
.companyMenuLogoCont {
  width: 140px;
  height: 80px;
  position: relative;
  background-color: white;
  padding: 5px 20px 5px 5px;
}
.companyMenuLogo {
  max-height: 80px;
  max-width: 140px;
  width: auto;
  height: auto;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.companyMenuBody {
  max-width: 1190px;
  margin: 0 auto;
  border: 1px solid #dddddd;
  background-color: white;
  padding: 0;
}
.companyMenuItems {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}
.companyMenuItem {
  font-size: 20px;
  font-weight: bold;
  color: #494949;
  cursor: pointer;
}
.companyMenuItemSep {
  padding: 0 10px;
  color: #d1d1d3;
  font-size: 20px;
}
.summary {
  max-width: 1190px;
  margin: 0 auto;
  background-color: white;
  padding: 0 30px 30px 30px;
  display: block;
  flex-direction: row;
  align-items: center;
}
.videos {
  display: flex;
}
.mainVideo {
  margin: 15px;
  width: 60%;
  text-align: center;
}
.othersVideo {
  flex-direction: row;
  flex-flow: row wrap;
  width: 40%;
  height: 420px;
  margin-right: 40px;
}
.sliderVideo {
  height: 400px;
}
.slick-next:before,
.slick-prev:before {
  color: #000;
}
.otherVideoItem {
  display: block;
  width: auto!important;
  padding: 10px;
  height: 160px;
  border: 1px solid #dddddd;
  background-color: #e8e6e6;
  text-align: center;
  cursor: pointer;
  margin: 10px;
  font-size: 14px;
  text-align: -webkit-center;
}
.otherVideoItem > img {
  width: 180px;
}
.currentItem {
  color: #c8000b;
  /*text-decoration: underline;*/
  cursor: pointer;
}
.whitepappers {
  margin: 15px;
}
.eventItem {
  margin: 0 20px 20px 20px;
}
.eventItem .locationIcon {
  width: 25px;
}
.eventItem .stand {
  font-weight: bold;
}
.whitepappersItem {
  margin-top: 20px;
  flex-direction: row;
  display: flex;
  align-items: center;
}
.whitepappersItem .info {
  line-height: 22px;
  font-size: 17px;
}
.whitepappersItem .info .title {
  text-decoration: none;
  color: #000;
  font-size: 20px;
  font-weight: bold;
}
.whitepappersItem .info .download {
  font-size: 15px;
}
.whitepappersItem .pdfIcon {
  margin-right: 20px;
  margin-left: 10px;
}
.summaryInfo {
  display: flex;
  align-items: flex-start;
}
.summaryAbout {
  background-color: #ebebeb;
  padding: 20px;
  width: 300px;
  min-width: 300px;
  margin-right: 20px;
}
.certificateItem {
  padding: 10px 10px 10px 0;
  display: inline-flex;
  align-items: center;
}
.certificateItem > img {
  width: 50px;
}
.certificateItem > div {
  padding-left: 10px;
}
.summaryText {
  padding: 0px 10px 20px 0px;
}
.summaryText > .cerftificateList {
  display: block;
}
.markets > h4 {
  font-weight: bold;
}
.companyMenuBody .companyName {
  display: none;
}
@media all and (max-width: 900px) {
  .companyMenuItem {
    font-size: 14px;
    padding: 5px;
  }
  .companyMenuLogoCont {
    width: 100px;
    height: 70px;
  }
  .companyMenuLogo {
    max-height: 70px;
    max-width: 100px;
  }
}
@media all and (max-width: 600px) {
  .companyMenuItems {
    padding-left: 8px;
    flex-direction: row;
    /* padding-bottom: 20px; */
  }
  .companyMenuItemSep {
    display: none;
  }
  .companyMenuLogoCont {
    padding: 5px;
  }
  .companyMenuItem {
    flex-shrink: 0;
    width: 95%;
    border: 1px solid #dddddd;
    margin: 5px;
    padding: 5px;
  }
  .companyMenuItems .companyMenuLogo {
    flex: 1 0 40%;
  }
  .companyMenuBody .companyName {
    display: block;
    font-weight: bold;
    margin-left: 20px;
    font-size: 14px;
    flex: 0 1 50%;
    text-align: center;
  }
}
@media all and (max-width: 600px) {
  .summary {
    font-size: 14px;
    padding: 0 15px 15px 15px;
  }
  .companyMenuItem {
    display: none;
  }
}
