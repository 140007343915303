.productStatus {
  position: absolute;
  padding: 4px 7px;
  margin: 0px 0px 0px 5px;
  top: 0;
  right: 0;
  color: white;
  z-index: 1000;
  font-family: 'Trebuchet MS', Arial, sans-serif;
  font-size: 16px;
}
.productIsNew {
  position: relative;
}
.productStatusNew {
  background-color: #c1001d;
}
.productStatusPreOwned {
  background-color: #414142;
  position: relative;
}
#productDescription .productStatus {
  left: auto;
  right: 5px;
  top: 5px;
}
.productStatusDiv {
  position: absolute;
  padding: 4px 7px;
  margin: 0px 0px 0px 5px;
  top: 0;
  right: 0;
  padding-left: 40px;
  display: flex;
  justify-content: flex-end;
}
