.HeaderSearch,
.HeaderSearchMobile {
  padding: 10px;
  display: flex;
  align-items: baseline;
  position: absolute;
  left: 65%;
  top: 50%;
  width: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.HeaderSearchMobileBar {
  position: absolute;
  top: 55px;
  right: 0;
  width: 90%;
  height: 50px;
  z-index: 1;
  border-radius: 0 0 5px 5px;
}
.HeaderSearchMobileBar .HeaderSearchMobile {
  left: 50%;
  width: 95%;
}
.HeaderSearchMobileBar .HeaderSearchMobile > input {
  width: 100%;
}
.HeaderSearch > input,
.HeaderSearchMobile > input {
  height: 22px;
  width: 100%;
  padding: 4px;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
}
.HeaderSearch > input::placeholder,
.HeaderSearchMobile > input::placeholder {
  font-style: italic;
  color: #a9a9ab;
}
.HeaderSearch > button,
.HeaderSearchMobile > button {
  padding: 5px 10px;
  margin-left: -38px;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-color: #c8000b;
  border: 1px solid;
  cursor: pointer;
  display: inline;
}
.HeaderSearchButton {
  margin-left: 20px;
}
@media all and (max-width: 920px) {
  .HeaderSearch {
    display: none!important;
  }
}
@media all and (max-width: 600px) {
  .HeaderSearchButton {
    margin-left: 0;
  }
  .TopMenuIconsSearch {
    margin-right: 10px;
  }
}
