/* .dropDawnMenuBgr{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0, 0.2);
    z-index: ;
} */
.loginModal {
  background-color: #d1000c;
  z-index: 1000;
  position: absolute;
  /* width: 235px; */
  /* height: 100px; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  top: 54px;
  border-radius: 0 0 5px 5px;
}
#loginModal .socialButton {
  width: 200px;
  height: auto;
}
#loginModal .loginInput {
  width: 192px;
  height: 20px;
  padding: 3px;
  margin: 0 0 2px 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
}
#loginModal h3 {
  padding: 0;
  margin: 0 0 10px 0;
  color: white;
  font-size: 15px;
}
#loginModal h3.or {
  text-align: center;
  padding: 15px 0 5px 0;
}
#loginModal .loginInputButton {
  padding: 5px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #fbb831;
  background-color: #c8000b;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 3px;
  margin: 5px 0 5px 0;
  font-size: 14px;
  text-decoration: none;
}
#loginModal .loginInputButton:hover {
  border: 1px solid  white;
  background-color: #ff000e;
}
#loginModal .loginInputButtonRegister {
  margin-left: 5px;
}
#loginModal .helloText {
  color: white;
  padding-bottom: 20px;
}
#loginModal .buttonsDiv {
  text-align: center;
}
#loginModal #login {
  width: 235px;
}
#loginModal #register {
  width: 201px;
}
