.searchBoxLine {
  background: #414142;
}
.searchBox {
  width: 100%;
  display: flex;
  align-items: center;
  /* Vertical alignment */
  flex-direction: row;
  background-color: white;
}
.searchBody {
  max-width: 1190px;
  margin: 0 auto;
  background-image: url("../../elements/images/serach-bgr.jpg");
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: right;
}
.slogan {
  font-size: 30px;
  color: white;
  font-style: italic;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 10px;
}
.slogan a,
.slogan a:visited {
  color: #c8000b;
  text-decoration: underline;
  font-size: 30px;
}
.slogan a:hover {
  text-decoration: none;
}
.searchForm {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px;
}
.searchBoxLine .searchForm {
  width: 100%;
}
.searchBoxWithCategory .searchForm {
  width: 100%;
}
.findCategorySelectorSize {
  font-size: 14px;
  visibility: hidden;
  position: absolute;
}
.searchBox {
  width: 100%;
  display: flex;
  align-items: center;
  /* Vertical alignment */
  flex-direction: row;
  background-color: white;
}
.searchInput {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #f1f1f1;
}
.searchInput .categorySelector {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  font-size: 14px;
  padding: 4px 3px;
  border: 1px solid white !important;
  height: 32px;
  border-radius: 7px;
  color: white;
  margin: 1px;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 3px;
}
.searchInput .categorySelector .category {
  font-weight: bolder;
}
.searchInput input {
  /* width: 100%; */
  background: #f1f1f1;
  height: 28px;
  font-size: 20px;
  padding: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
  /* flex-grow: 3; */
  flex-grow: 3;
}
.searchInput input::placeholder {
  font-size: 19px;
  font-style: italic;
  color: #a9a9ab;
}
.searchButton {
  /* margin-left: -59px;  */
}
.searchButton button {
  -moz-appearance: none;
  border-left: 3px solid #c8000b;
  border-top: 1px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
  border-right: 1px solid #d4d4d4;
  color: #797979;
  padding: 5px 5px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 3px;
}
.inputAndButton {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: white;
}
@media all and (max-width: 900px) {
  .slogan {
    font-size: 20px;
  }
  .searchForm {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 30px;
  }
  .searchInput input {
    font-size: 19px;
    font-size: 14px;
  }
  .searchInput input::placeholder {
    font-size: 14px;
  }
  .searchInput .categorySelector {
    font-size: 12px;
    padding: 1px 3px;
  }
  .findCategorySelectorSize {
    font-size: 12px;
  }
}
@media all and (max-width: 550px) {
  .slogan {
    font-size: 16px;
  }
  .searchForm {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 20px;
  }
  .searchInput {
    flex-direction: column;
  }
  .searchInput .categorySelector {
    font-size: 14px;
    width: 100%!important;
    border: 2px solid white;
  }
  .findCategorySelectorSize {
    font-size: 14px;
  }
  .searchBox,
  .searchForm {
    border-radius: 7px;
  }
}
