.productDescriptionLine {
  background-color: #f7f7f7;
}
.productDescription {
  max-width: 1110px;
  margin: 0 auto;
  border: 1px solid #dddddd;
  background-color: white;
  padding: 40px;
  padding-top: 10px;
  margin-top: 10px;
  position: relative;
  overflow: hidden;
}
.productDescription .contactUsbutton {
  display: none;
}
.descriptionName {
  font-size: 32px;
  font-weight: bold;
  font-family: 'Trebuchet MS', Arial, sans-serif;
  color: #494949;
}
.descriptionModel {
  font-size: 18px;
  font-weight: bold;
  font-family: 'Trebuchet MS', Arial, sans-serif;
  color: #494949;
}
.descriptioNameBody {
  flex-direction: row;
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  padding-top: 20px;
}
.descriptionImageAndDescr {
  display: flex;
  flex-direction: row;
}
.descriptionBookmark {
  background: #e8e6e6;
  padding: 10px 20px;
  color: black;
  font-size: 20px;
  font-family: 'Trebuchet MS', Arial, sans-serif;
  display: inline-block;
}
.descriptionDescrBody {
  margin-left: 40px;
}
.descriptionDescrText {
  color: #494949;
  font-size: 18px;
  font-weight: normal;
  border: 1px solid #d1d1d3;
  padding: 20px;
  margin-bottom: 30px;
}
.descriptionFeatures {
  padding-top: 40px;
}
.descriptionTags {
  justify-content: end;
  text-decoration: none;
  color: #c1001d;
}
.descriptionTagsList {
  padding-top: 25px;
  text-align: right;
  font-size: 14px;
  width: 50%;
  float: right;
}
.descriptionTagsList span {
  white-space: nowrap;
}
.descriptionImages {
  min-width: 450px;
  /* min-height: 450px; */
  display: flex;
  flex-direction: column;
  /* border: 2px solid #d9d7d7; */
}
.descriptionImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.descriptionVideo {
  max-width: 450px;
  max-height: 450px;
  width: 100%;
}
.certBorder {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin: 10px;
  padding-bottom: 45px;
}
.certBorder > img {
  width: 50px;
}
.descriptionDocumentsItem {
  padding: 5px;
  font-size: 19px;
  font-weight: bold;
}
.descriptionDocumentsItem > a {
  padding: 10px;
  font-size: 12px;
}
.certBorder > a {
  font-size: 10px;
  padding-top: 25px;
  padding-left: 30px;
}
.descriptionCertificateList {
  color: #494949;
  font-size: 18px;
  font-weight: normal;
  border: 1px solid #d1d1d3;
  padding: 20px;
  margin-bottom: 30px;
  display: flex;
}
.descriptionImageSwitch {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 450px;
  margin-top: 13px;
}
.descriptionImageBox {
  display: flex;
  height: 450px;
  max-width: 450px;
  text-align: center;
  border: 2px solid #d9d7d7;
}
@media all and (max-width: 700px) {
  .descriptionImageBox {
    width: 350px;
    height: 350px;
  }
}
@media all and (max-width: 500px) {
  .descriptionImageBox {
    width: 250px;
    height: 250px;
  }
}
.descriptionReqButton {
  padding: 9px 20px 9px 9px;
  background: #c1001d;
  color: white;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  font-family: 'Trebuchet MS', Arial, sans-serif;
  cursor: pointer;
  width: max-content;
  position: relative;
  min-width: 250px;
  border-radius: 7px;
}
@media all and (max-width: 700px) {
  .descriptionReqButton {
    padding: 12px 20px 12px 9px;
    border-radius: 20px;
    text-align: center;
  }
}
.descriptionReqButton .leftLine {
  background-color: white;
  width: 2px;
  left: 7px;
  top: 7px;
  bottom: 7px;
  margin-right: 3px;
  position: absolute;
}
@media all and (max-width: 700px) {
  .descriptionReqButton .leftLine {
    display: none;
  }
}
.descriptionReqIcon {
  padding-left: 10px;
  padding-right: 10px;
  font-weight: normal;
  font-size: 19px;
}
.currentImageSwitch {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border: 1px solid #c1001d;
}
.imageSwitch {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border: 1px solid #d9d7d7;
}
.descriptionProductStatus {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  height: 40px;
}
.descriptionProductStatusNew {
  position: relative;
  margin-right: 40px;
}
.descriptionProductStatuspre-owned {
  position: relative;
  margin-right: 95px;
}
.descriptionNameIcon {
  padding-left: 10px;
}
.firstClickBlock {
  display: none;
}
@media all and (max-width: 900px) {
  .productDescription .contactUsbutton {
    display: block;
    width: -webkit-fill-available;
    margin: 30px 0;
  }
  .descriptioNameBody {
    padding-top: 0;
  }
  .descriptionImageAndDescr {
    flex-direction: column;
  }
  .descriptionDescrBody {
    margin-left: 0;
    padding-top: 15px;
  }
  .descriptioNameBody {
    flex-direction: column;
  }
  .descriptionImages {
    margin: 0 auto;
  }
  .productDescription {
    padding: 30px 15px 15px 15px;
  }
  .descriptionImages {
    min-width: auto;
  }
  .descriptionName {
    font-size: 22px;
  }
  .descriptionModel {
    font-size: 15px;
  }
  .descriptionBookmark {
    padding: 5px 10px;
    font-size: 14px;
  }
  .descriptionDescrText {
    font-size: 14px;
    padding: 15px;
  }
  .descriptionReqButton {
    min-width: none;
    width: -webkit-fill-available;
    /* padding: 3px 10px 3px 3px; */
    /* font-size: 14px; */
  }
  .descriptionReqIcon {
    /* font-size: 15px; */
  }
  .descriptionTagsList {
    font-size: 11px;
    padding-top: 15px;
  }
  .firstClickBlock {
    display: contents;
  }
}
