#Hospimedica .modalShadow .modalWindow {
  border-color: #2fd1d6;
}
#Hospimedica .modalShadow .modalHeader {
  background-color: #2fd1d6;
}
#Hospimedica .modalShadow .sendButton {
  background-color: #2fd1d6;
}
#Hospimedica .paginationLine .paginationBody .pagination .page {
  color: #2fd1d6;
}
#Hospimedica .paginationLine .paginationBody .pagination .current {
  border-color: #2fd1d6;
  background-color: #2fd1d6;
}
#Hospimedica .companyMenuLine .companyMenuBody .companyMenuItems .currentItem {
  color: #2fd1d6;
}
#Hospimedica .descriptionTags {
  color: #2fd1d6;
}
#Hospimedica .productIsNew {
  background-color: #2fd1d6;
}
#Hospimedica .descriptionReqButton {
  background: #2fd1d6;
}
#Hospimedica .HeaderStyle {
  background: #00b6bd;
}
#Hospimedica .HeaderStyle .HeaderBodyStyle .HeaderMenu .HeaderSearchMobile > button {
  border-color: #00b6bd;
  background-color: #00b6bd;
}
#Hospimedica .HeaderStyle .HeaderBodyStyle .HeaderMenu .HeaderSearchMobileBar {
  background-color: #047f83;
}
#Hospimedica .HeaderStyle .HeaderBodyStyle .HeaderMenu .HeaderSearchMobileBar .HeaderSearchMobile > button {
  border-color: #00b6bd;
  background-color: #00b6bd;
}
#Hospimedica .HeaderStyle .HeaderBodyStyle .HeaderMenu .loginDiv .dropDawnMenuBgr .loginModal {
  background-color: #00b6bd;
}
#Hospimedica .HeaderStyle .HeaderBodyStyle .HeaderMenu .loginDiv .dropDawnMenuBgr .loginModal .loginInputButton {
  background-color: #027f83;
}
#Hospimedica .HeaderStyle .HeaderBodyStyle .HeaderMenu .TopMenuMenuItem #dropDawnMenu {
  background-color: #027f83;
}
#Hospimedica .HeaderStyle .HeaderBodyStyle .HeaderMenu .TopMenuMenuItem #dropDawnMenu .menuItem:hover {
  background-color: #2fd1d6;
}
#Hospimedica .searchBoxLine .searchBody .searchForm .searchBox .searchInput .categorySelector {
  background-color: #00b6bd;
}
#Hospimedica .searchBoxLine .searchBody .searchForm .searchBox .searchInput .inputAndButton .searchButton button {
  border-left-color: #00b6bd;
}
#Hospimedica .Menus .MenuItem {
  background: #00b6bd;
}
#Hospimedica .Menus .CategoriesListLineModal {
  top: 26%;
}
#Hospimedica .Menus .CategoriesListModal {
  background: #00b6bd;
}
#Hospimedica .Menus .CategoriesListModal .categoryTextModal:hover {
  background-color: #2fd1d6;
}
#Hospimedica .CategoriesListLine .CategoriesList .CategoriesListBlock .categoryDiv {
  border-left-color: #2fd1d6;
}
#Hospimedica .productsLine .productsBody .productsGrid .productFeaturedBox,
#Hospimedica .productsLine .productsBodyBoxed .productsGrid .productFeaturedBox {
  border-bottom-color: #2fd1d6;
}
#Hospimedica .productsLine .productsBody .productsGrid .productBox,
#Hospimedica .productsLine .productsBodyBoxed .productsGrid .productBox {
  border-bottom-color: #2fd1d6;
}
#Hospimedica .productsLine .productsBody .productsGrid .overlayModal .overlay,
#Hospimedica .productsLine .productsBodyBoxed .productsGrid .overlayModal .overlay {
  border-color: #2fd1d6;
}
#Hospimedica .PageNotFound .BackButton {
  background-color: #2fd1d6;
}
#Labmedica .modalShadow .modalWindow {
  border-color: #910008;
}
#Labmedica .modalShadow .modalHeader {
  background-color: #910008;
}
#Labmedica .modalShadow .sendButton {
  background-color: #c8000b;
}
#Labmedica .paginationLine .paginationBody .pagination .page {
  color: #c1001d;
}
#Labmedica .paginationLine .paginationBody .pagination .current {
  border-color: #c1001d;
  background-color: #c1001d;
}
#Labmedica .descriptionTags {
  color: #c1001d;
}
#Labmedica .descriptionReqButton {
  background: #c1001d;
}
#Labmedica .productIsNew {
  background-color: #c1001d;
}
#Labmedica .HeaderStyle {
  background: #c8000b;
}
#Labmedica .HeaderStyle .HeaderBodyStyle .HeaderMenu .HeaderSearchMobile > button {
  border-color: #c8000b;
  background-color: #c8000b;
}
#Labmedica .HeaderStyle .HeaderBodyStyle .HeaderMenu .HeaderSearchMobileBar {
  background-color: #910008;
}
#Labmedica .HeaderStyle .HeaderBodyStyle .HeaderMenu .HeaderSearchMobileBar .HeaderSearchMobile > button {
  border-color: #c8000b;
  background-color: #c8000b;
}
#Labmedica .HeaderStyle .HeaderBodyStyle .HeaderMenu .loginDiv .dropDawnMenuBgr .loginModal {
  background-color: #d1000c;
}
#Labmedica .HeaderStyle .HeaderBodyStyle .HeaderMenu .loginDiv .dropDawnMenuBgr .loginModal .loginInputButton {
  background-color: #c8000b;
}
#Labmedica .HeaderStyle .HeaderBodyStyle .HeaderMenu .TopMenuMenuItem #dropDawnMenu {
  background-color: #d1000c;
}
#Labmedica .HeaderStyle .HeaderBodyStyle .HeaderMenu .TopMenuMenuItem #dropDawnMenu .menuItem:hover {
  background-color: #e4000d;
}
#Labmedica .searchBoxLine .searchBody .searchForm .searchBox .searchInput .categorySelector {
  background-color: #c8000b;
}
#Labmedica .searchBoxLine .searchBody .searchForm .searchBox .searchInput .inputAndButton .searchButton button {
  border-left-color: #c8000b;
}
#Labmedica .Menus .MenuItem {
  background: #c1001d;
}
#Labmedica .Menus .CategoriesListLineModal {
  top: 10%;
}
#Labmedica .Menus .CategoriesListModal {
  background: #d1000c;
}
#Labmedica .Menus .CategoriesListModal .categoryTextModal:hover {
  background-color: #e4000d;
}
#Labmedica .CategoriesListLine .CategoriesList .CategoriesListBlock .categoryDiv {
  border-left-color: #c1001d;
}
#Labmedica .productsLine .productsBody .productsGrid .productFeaturedBox,
#Labmedica .productsLine .productsBodyBoxed .productsGrid .productFeaturedBox {
  border-bottom-color: #c8000b;
}
#Labmedica .productsLine .productsBody .productsGrid .productBox,
#Labmedica .productsLine .productsBodyBoxed .productsGrid .productBox {
  border-bottom-color: #c8000b;
}
#Labmedica .productsLine .productsBody .productsGrid .overlayModal .overlay,
#Labmedica .productsLine .productsBodyBoxed .productsGrid .overlayModal .overlay {
  border-color: #910008;
}
#Labmedica .PageNotFound .BackButton {
  background-color: #c8000b;
}
