.suppliersBarLine {
  margin-top: 10px;
  width: 100%;
  background-color: #ffffff;
  border-top: 2px solid #dddddd;
}
.suppliersBarBody {
  max-width: 1180px;
  margin: 0 auto;
  padding: 0;
  background-color: white;
}
.suppliersBarBody .suppliersBarLogo {
  background-color: #414143;
  color: white;
  display: block;
  padding: 5px 10px;
  margin: 0;
  width: auto;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Trebuchet MS', Arial, sans-serif;
}
.suppliersBarBody .suppliersBarItems {
  padding: 10px 30px 30px 30px;
  /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
}
#suppliersBarBody .slick-next,
#suppliersBarBody .slick-prev {
  z-index: 100;
}
/* #suppliersBarBody .slick-next {
    right: 5px;
}

#suppliersBarBody .slick-prev {
    left: 5px;
} */
#suppliersBarBody .slick-next:before,
#suppliersBarBody .slick-prev:before {
  font-size: 23px;
  line-height: 1;
  opacity: 0.5;
  color: #414142;
}
#suppliersBarBody > div {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
}
#suppliersBarBody .sliderSlide {
  margin-bottom: -5px;
}
