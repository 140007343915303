.supplierStatus {
  position: absolute;
  font-size: 11px;
  font-family: 'Trebuchet MS', Arial, sans-serif;
  font-weight: bold;
  padding: 4px 7px;
  text-align: center;
  bottom: 0;
  left: 0;
  color: #494949;
  text-shadow: 1px 1px 1px white;
  /* Параметры тени */
  z-index: 100;
}
.productBorder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 4px solid #d1d1d3;
  z-index: 10;
}
.productBorderNoStatus {
  border-width: 1px!important;
}
.statusPlatinum {
  background-image: url('../../elements/images/platinum-bgr.jpg');
  background-color: #cfd0d2;
}
.satusGold {
  background-image: url('../../elements/images/gold-bgr.jpg');
  background-color: #edc93d;
}
.satusSilver {
  background-image: url('../../elements/images/silver-bgr.jpg');
  background-color: #c7c7c7;
}
#companyMenuBody {
  position: relative;
}
#companyMenuBody .supplierStatus {
  position: absolute;
  bottom: auto;
  left: auto;
  top: 0;
  right: 0;
  font-size: 16px;
}
@media all and (max-width: 600px) {
  .supplierStatus {
    font-size: 10px;
    padding: 2px 4px;
  }
  #companyMenuBody .supplierStatus {
    font-size: 12px;
  }
}
