.PageNotFound {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.PageNotFound .Title {
  font-size: 45px;
  font-weight: bolder;
  color: #494949;
}
.PageNotFound .searchBoxLine {
  width: 100%;
}
.PageNotFound .BackButton {
  font-size: 25px;
  text-decoration: none;
  color: white;
  padding: 5px 15px;
  margin-top: 10px;
  font-weight: bold;
  border-radius: 3px;
  border: 1px solid #fbb831;
}
@media all and (max-width: 650px) {
  .PageNotFound .Title {
    font-size: 25px;
  }
}
