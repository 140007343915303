.BodyStyle {
  background: #f7f7f7;
}
.BodyStyleItems {
  display: inline-block;
  width: 80%;
  padding: 20px;
  background: #FFFFFF;
  color: black;
}
.ThankPageTitle {
  text-align: left;
  font-size: 20px;
}
.empProduct {
  flex: 1 0 21%;
  /* flex: 1 0 40%; */
  margin: 0 5px 0 5px;
}
@media all and (max-width: 950px) {
  .empProduct {
    flex: 1 0 30%;
  }
}
@media all and (max-width: 850px) {
  .empProduct {
    flex: 1 0 40%;
  }
}
