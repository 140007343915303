.requestModalPrice {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}
.requestModalBodyPrice {
  background-color: gray;
  position: absolute;
  z-index: 1000;
  padding: 20px;
  width: 600px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
