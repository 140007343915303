.productLogo {
  display: inline-block;
  width: 115px;
  height: 78px;
  background-position: center;
  background-size: 95%;
  background-repeat: no-repeat;
  background-color: white;
}
/* .imgLogo{
    height: 80%;
    width: 80%;
} */
