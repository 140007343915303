.CompanyProductsBodyStyle {
  background: #f7f7f7;
}
.BodyStyleItems {
  display: inline-block;
  width: 80%  ;
  min-width: 800px;
  padding: 25px ;
  border: 2px solid #d9d7d7;
  background: #FFFFFF;
  color: black  ;
}
.ProductsList {
  display: grid;
  justify-content: center;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fit, 240px);
  grid-template-rows: repeat(2, 340px);
}
.moreProductsButton {
  display: none;
}
@media all and (max-width: 600px) {
  .moreProductsButton {
    display: block;
    padding: 10px 0 10px 0;
    margin: 5px;
    text-align: center;
    color: white;
    background: #c1001d;
    font-weight: bold;
    font-family: 'Trebuchet MS', Arial, sans-serif;
    cursor: pointer;
  }
}
