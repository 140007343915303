.HeaderStyle {
  position: fixed;
  width: 100%;
  overflow: visible;
  top: 0;
  height: 55px;
  z-index: 10000;
}
.HeaderBodyStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  max-width: 1190px;
  margin: 0 auto;
  align-items: center;
  height: 55px;
}
.HeaderLogo {
  padding: 5px 10px 0 10px;
}
.HeaderBodyStyle a,
.HeaderBodyStyle a:visited {
  color: white;
  font-size: 14px;
  text-decoration: none;
}
.HeaderBodyStyle a:hover {
  text-decoration: underline;
}
.HeaderLoginButton {
  padding-left: 20px;
  padding-right: 20px;
}
.HeaderMenu {
  display: flex;
}
.TopMenuMenuItem {
  padding-left: 20px;
  padding-right: 5px;
}
@media all and (max-width: 900px) {
  .HeaderLogoImg {
    width: 220px;
    height: auto;
  }
}
@media all and (max-width: 600px) {
  .HeaderAdvertiseWithUs {
    /* position: absolute;
        top: -4px;
        left: 0px;
        display: block;
        background-color: #e4000d;
        padding: 0 3px; */
    display: none;
  }
  .HeaderAdvertiseWithUs a {
    font-size: 10px;
  }
  .TopMenuMenuItem {
    padding-left: 8px;
    padding-right: 5px;
  }
  .HeaderMenu {
    padding-top: 6px;
  }
  .HeaderLogo {
    padding: 10px 10px 0 10px;
  }
}
@media all and (max-width: 360px) {
  .HeaderLogoImg {
    width: 170px;
    height: auto;
  }
  .HeaderLogo {
    padding: 10px 2px 0 10px;
  }
}
