.TopMenuIcons {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.TopMenuDropDownMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
