.overlayModal {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 5000;
}
.overlay {
  border-style: solid;
  border-width: 3px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
}
.overlayBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 2px 8px 2px 8px;
}
#overlayQuickInquiry {
  min-width: auto;
}
.overlayContactUs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  border-bottom: 2px grey dotted;
  margin-bottom: 10px;
}
.contactUsbutton {
  background-color: #910008;
  color: white;
  padding: 5px 8px;
  margin: 10px;
}
.contactUsbutton:hover {
  background-color: #e4000d;
  color: white;
}
.overlayDescribe {
  position: relative;
  flex-grow: 4;
  background-color: rgba(255, 255, 255, 0.2);
}
.separator {
  width: inherit;
  height: 4px;
  background-color: grey;
}
.overlayProductTitle {
  font-size: 15px;
  font-weight: bold;
}
/* .overlayModal .overlay .featuredOverlay {
    height: 458px;

}

.overlayModal .overlay .productOverlay{
    height: 403px;
}

.overlayModal .overlay .recentlyOverlay{
    height: 335px;
}

@media all and (max-width: 1100px) {
    .overlayModal .overlay .featuredOverlay {
      height: 403px;
    }

    .overlayModal .overlay .productOverlay {
      height: 230px;
  }

}

@media all and (max-width: 930px) {
    .overlayModal .overlay .featuredOverlay {
      height: 370px;
  }
}

@media all and (max-width: 864px) {

    .overlayModal .overlay .featuredOverlay {
      height: 390px;
  }

  .overlayModal .overlay .productOverlay {
      height: 280px;
  }
}

@media all and (max-width: 850px) {

    .overlayModal .overlay .featuredOverlay {
      height: 393px;
  }

  .overlayModal .overlay .productOverlay {
      height: 280px;
  }
} */
@media all and (max-width: 1150px) {
  .productOverlay,
  .featuredOverlay {
    flex: 1 0 30%;
  }
  #overlayQuickInquiry {
    font-size: 14px;
    margin: 0;
    text-align: center;
    margin-bottom: 10px;
  }
  #overlayQuickInquiry .descriptionReqIcon {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media all and (max-width: 850px) {
  .productOverlay,
  .featuredOverlay {
    flex: 2 0 40%;
  }
}
/* @media all and (max-width: 1165px) {
    .overlayModal .overlay .productOverlay{
        height: 407px;
    }
    

}

@media all and (max-width: 1100px) {
    .overlayModal .overlay .featuredOverlay  {
      height: 405px;
    }

    .overlayModal .overlay .productOverlay{
        height: 370px;
    }
}


@media all and (max-width: 979px) {

    .overlayModal .overlay .productOverlay{
        height: 350px;
    }
}


@media all and (max-width: 930px) {

    .overlayModal .overlay .featuredOverlay  {
        height: 375px;
    }

    .overlayModal .overlay .productOverlay{
        height: 350px;
    }
}

@media all and (max-width: 879px) {

    .overlayModal .overlay .featuredOverlay {
        height: 380px;
    }

    .overlayModal .overlay .productOverlay{
        height: 375px;
    }
}

@media all and (max-width: 850px) {

    .overlayModal .overlay .featuredOverlay {
        height: 395px;
    }

    .overlayModal .overlay .productOverlay{
        height: 375px;
    }
}

@media all and (max-width: 225px) {

    .overlayModal .overlay .featuredOverlay {
        height: 410px;
    }

} */
