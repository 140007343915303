.dropDawnMenuBgr {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
#dropDawnMenu {
  display: inline-block;
  color: white;
  padding: 10px 15px;
  position: absolute;
  top: 54px;
  border-radius: 0 0 5px 5px;
}
#dropDawnMenu .menuItem {
  display: block;
  padding: 5px;
  font-size: 14px;
  white-space: nowrap;
}
#dropDawnMenu .menuItem:hover {
  text-decoration: none;
  border-radius: 5px;
}
#dropDawnMenu h3 {
  font-size: 16px;
  padding: 0;
  margin: 20px 0 10px 0;
}
