.SliderLine {
  background-color: #eaeaec;
}
.SliderBody {
  max-width: 1190px;
  margin: 0 auto;
}
.bannerImg {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
  max-height: 270px;
}
#TopSlider .slick-next,
#TopSlider .slick-prev {
  z-index: 100;
}
#TopSlider .slick-next {
  right: 5px;
}
#TopSlider .slick-prev {
  left: 5px;
}
#TopSlider .slick-dots {
  bottom: 0;
}
#TopSlider .slick-next:before,
#TopSlider .slick-prev:before {
  font-size: 23px;
  line-height: 1;
  opacity: 0.8;
  color: #a59494;
}
#TopSlider > div {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
}
#TopSlider .sliderSlide {
  margin-bottom: -5px;
}
@media all and (max-width: 700px) {
  #TopSlider .slick-dots {
    display: none!important;
  }
}
