#newsModal {
  width: 80%;
  height: 70%;
  overflow-y: auto;
}
#newsModal .body {
  padding: 25px 15px;
}
#newsModal .closeButton {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
}
#newsModal .image {
  padding-left: 25px;
  padding-bottom: 15px;
  float: right;
}
#newsModal .image > img {
  width: 450px;
}
#newsModal .imageText {
  width: 450px;
  font-style: italic;
  font-size: 14px;
}
