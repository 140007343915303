#root {
  padding-top: 55px;
  height: 100%;
}
body {
  font-family: 'Open Sans', sans-serif;
  background-color: #f7f7f7;
  height: 100%;
  box-sizing: border-box;
  min-height: 100%;
}
input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none;
}
h1,
h2,
h3 {
  font-family: 'Trebushet MS', sans-serif;
}
h2 {
  font-size: 24px;
  color: #494949;
}
/* Products Grid */
.productsLine {
  margin-top: 10px;
  width: 100%;
  background-color: #f7f7f7;
}
.productsBody {
  max-width: 1110px;
  margin: 0 auto;
  padding: 20px 40px;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  background-color: white;
}
.productsGrid {
  display: flex;
  flex-direction: row;
  /* flex: 0 0 25%; */
  /*justify-content: space-between;*/
  /*align-content: flex-start;*/
  flex-flow: row wrap;
}
/* .productsGrid::after {
       content: "";
       flex: auto;
       width: 300px;
     } */
.productsBodyBoxed {
  max-width: 1110px;
  margin: 10px auto;
  padding: 20px 40px;
  border: 1px solid #dddddd;
  background-color: white;
}
.productsFeaturedGrid {
  display: flex;
  flex-direction: row;
  flex: 0 0 33%;
  justify-content: space-between;
  align-content: flex-start;
  flex-flow: row wrap;
}
.paginationLine {
  width: 100%;
  background-color: #f7f7f7;
}
.paginationLine .paginationBody {
  max-width: 1110px;
  margin: 0 auto;
  padding: 20px 40px;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  background-color: white;
}
.paginationLine .pagination {
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.paginationLine .pagination .page {
  background-color: white;
  padding: 5px;
  margin: 2px;
  border: 2px #d1d1d3 solid;
  text-decoration: none;
  cursor: pointer;
}
.paginationLine .pagination .current {
  text-decoration: none;
  color: white;
  padding: 5px;
  margin: 2px;
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
}
.inputHidden {
  display: none;
}
.hidden {
  display: none;
}
/*Modals*/
.modalShadow {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 6000;
  overflow-y: auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.modalWindow {
  color: black;
  z-index: 1000;
  padding: 0;
  background-color: white;
  border-width: 3px;
  border-style: solid;
  position: relative;
  padding-bottom: 40px;
  /* width: 50%; */
}
.modalWindow .modalClose {
  text-align: end;
  padding-right: 5px;
  position: absolute;
  right: 6px;
  top: 6px;
  cursor: pointer;
  z-index: 1000;
}
.modalWindow .modalHeader {
  /* position: absolute; */
  /* top:0;
       left: 0;
       right: 0; */
  margin: 0;
  padding: 15px;
  color: white;
  z-index: 900;
  text-align: center;
  border: 1px solid white;
}
.modalWindow .modalCheck {
  display: block;
}
.modalWindow .modalCheck label {
  font-size: 11px !important;
  display: inline-block !important;
}
.modalWindow .modalCheck label a,
.modalWindow .modalCheck label a:visited {
  font-size: 11px;
  color: #910008;
}
.modalWindow .modalCheck input {
  display: inline-block;
  width: auto !important;
  min-width: auto;
}
.modalWindow .formBlock > div {
  padding: 0 0 20px 0;
}
.modalWindow .formBlock > div label {
  display: inline-block;
  width: auto;
  font-size: 14px;
  font-weight: bold;
  color: #484848;
}
.modalWindow .formBlock > div input,
.modalWindow .formBlock > div select,
.modalWindow .formBlock > div textarea {
  width: 100%;
  font-size: 15px;
  border: 1px solid #a9a9ab;
  padding: 6px;
}
.modalWindow .formBlock {
  padding: 40px;
}
.modalWindow .formBlock > div textarea {
  min-height: 80px;
}
.modalWindow .formBlock .textLine {
  font-size: 14px;
  font-weight: bold;
  color: #484848;
}
.modalWindow .formBlock span {
  font-style: italic;
  font-weight: normal;
}
.modalWindow .inputHidden {
  display: none;
}
.modalWindow .sendButton {
  padding: 5px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #fbb831;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 3px;
  margin: 5px 0 5px 0;
  float: right;
}
.modalRequest {
  /* width: 40%!important; */
}
.modalInfo {
  min-width: 590px;
}
.newsItem {
  border-bottom: 1px dotted #6b6b6b;
  margin: 25px;
  text-align: left;
  padding: 25px 0;
  display: flex;
  flex-direction: row;
}
.newsItem img {
  max-width: 150px;
  max-height: 150px;
  height: auto;
  width: auto;
  margin: 0 20px 0 0;
}
.newsItem h4 {
  display: inline;
  margin: 0px;
  padding: 0px;
  font-size: 18px;
  font-weight: bold;
}
.newsItem h4 a {
  color: #033f7d;
  text-decoration: none;
}
.newsItem h4 a:visited {
  color: #033f7d;
}
.newsItem h4 a:hover {
  text-decoration: underline;
}
.newsItem .newsDate {
  white-space: nowrap;
  font-size: 12px;
  color: #949494;
}
#radio {
  width: auto;
}
@media all and (max-width: 600px) {
  .newsItem {
    flex-direction: column;
  }
  .newsItem .newsImage {
    max-width: 100%;
    height: auto;
    text-align: center;
  }
  .newsItem img {
    width: 100% !important;
    max-width: 350px !important;
    max-height: 350px !important;
    height: auto;
    margin: 0 auto 20px auto;
  }
}
@media all and (max-width: 700px) {
  .modalInfo {
    min-width: auto;
  }
}
@media all and (max-width: 500px) {
  .modalShadow,
  .modalShadowLarge {
    display: block !important;
  }
  .modalWindow .formBlock {
    padding: 20px 35px 20px 20px;
  }
}
@media all and (max-width: 1000px) {
  .modalWindow .modalHeader {
    font-size: 16px;
    padding: 10px 40px 10px 10px;
  }
  .modalWindow .modalClose {
    right: 2px;
    top: 2px;
  }
}
.formMessage {
  color: green;
  font-size: 12px;
}
/*Modals*/
@media all and (max-width: 850px) {
  h2 {
    font-size: 18px;
    color: #494949;
  }
  .productsBody {
    padding: 3px;
  }
}
@media all and (max-width: 500px) {
  .productsGrid {
    flex-direction: column;
    align-content: stretch;
  }
}
