.CategoriesListLineModal {
  top: 10%;
  left: 37px;
  z-index: 2000;
  position: fixed;
  /* background-color: rgba(230, 230, 230, 0.9); */
}
.CategoriesListModal {
  padding: 8px;
  border-radius: 5px;
}
.categoryDivModal {
  position: relative;
}
.subCategoryDivModal {
  position: absolute;
  left: 100%;
  top: -28%;
  width: max-content;
}
.categoryTextModal {
  font-size: 14px;
  color: white;
  text-decoration: none;
  display: block;
  padding: 4px;
}
.categoryTextModal:hover {
  background-color: #e4000d;
  border-radius: 5px;
}
