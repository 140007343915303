.productBox {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  background-color: #eaeaec;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: inherit;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  flex: 1 0 21%;
  margin: 5px 5px 10px 5px;
}
.productBox .topBlock,
.productFeaturedBox .topBlock,
.productRecentlyBox .topBlock {
  background-color: white;
  border: 1px solid #d1d1d3;
}
.productBox .topBlock {
  height: 270px;
}
.productFeaturedBox .topBlock {
  height: 352px;
}
.productRecentlyBox .topBlock {
  height: 240px;
}
.productBox .topBlockWsStatus,
.productFeaturedBox .topBlockWsStatus,
.productRecentlyBox .topBlockWsStatus {
  border: 4px solid #d1d1d3;
}
.productImg {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.productData {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-left: 1px solid #d1d1d3;
  border-top: 1px solid #d1d1d3;
  border-right: 1px solid #d1d1d3;
  background-color: #eaeaec;
  overflow: hidden;
  align-items: stretch;
  flex-grow: 2;
}
.productText {
  overflow: hidden;
  display: inline-block;
  padding: 5px;
  font-size: 15px;
  font-weight: bold;
  color: #494949;
  background-color: #eaeaec;
  width: 100%;
  min-height: 60px;
}
.productText .productModel {
  font-weight: normal;
  font-size: 12px;
  overflow: hidden;
}
.topBlock {
  position: relative;
  overflow: hidden;
}
.productLinkStyle {
  text-decoration: none;
  text-decoration-color: black;
  color: black;
}
.BodyStyleFeatured {
  width: 100%;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #cdd1ce;
}
.BodyStyleItemsFeatured {
  display: inline-block;
  width: 80%  ;
  padding: 25px ;
  background: #FFFFFF;
  color: black  ;
}
.productFeaturedBox {
  /*width: 354px;*/
  border-bottom-color: #c8000b;
  border-bottom: 2px solid;
  background: #eaeaec;
  margin-bottom: 10px;
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  flex: 1 0 30%;
  margin: 5px 5px 10px 5px;
  position: relative;
}
.productRecentlyBox {
  /*width: 354px;*/
  border-bottom: 2px solid #c8000b;
  background: #eaeaec;
  margin-bottom: 10px;
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  flex: 1 0 30%;
  margin: 5px 5px 10px 5px;
  position: relative;
}
.productLogo {
  width: 100px;
  min-height: 100px;
  height: auto;
  background-color: white;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.productLogo img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  max-width: 90px;
  max-height: 90px;
  height: auto;
  width: auto;
}
@media all and (max-width: 1100px) {
  .productFeaturedBox .topBlock {
    height: 300px;
  }
  .productBox .topBlock {
    height: 230px;
  }
}
@media all and (max-width: 930px) {
  .productFeaturedBox .topBlock {
    height: 270px;
  }
}
@media all and (max-width: 850px) {
  .productFeaturedBox .topBlock {
    height: 290px;
  }
  .productBox .topBlock {
    height: 280px;
  }
}
@media all and (max-width: 950px) {
  .productBox,
  .productFeaturedBox .productRecentlyBox {
    flex: 1 0 30%;
  }
}
@media all and (max-width: 850px) {
  .productBox,
  .productFeaturedBox .productRecentlyBox {
    flex: 1 0 40%;
  }
}
@media all and (max-width: 620px) {
  .productFeaturedBox {
    flex: 1 0 40%;
  }
}
